export const tasklist = [
  {
    id: 1,
    name: "Klugscheißer-Kindergarten",
    shortName: "vielZuLeicht",
    description: "Wer hier scheitert, hat die Grundschule verschlafen.",
    active: true,
    currentlyActive: false,
    tasks: [
      {
        frage: "Wieviele cl ergeben einen Liter?",
        antwort: "100cl = 1l",
      },
      {
        frage: "Welche deutsche Großstadt hat die größte Kriminalitätsrate?",
        antwort: "Berlin",
      },
      {
        frage: "Wofür steht das TS in Hamburger Royal TS?",
        antwort: "Tomate Salat",
      },
      {
        frage: "Was ist die Wurzel aus 64?",
        antwort: "8",
      },
      {
        frage: "Wieviele Bundesländer hat Deutschland?",
        antwort: "16",
      },
      {
        frage: "Wie viele Kontinente gibt es auf der Erde?",
        antwort:
          "Sieben. Asien, Afrika, Nordamerika, Südamerika, Antarktis, Europa und Australien",
      },
      {
        frage: "Wie viele Spieler hat eine Fußballmannschaft auf dem Feld?",
        antwort: "11",
      },
      {
        frage: "Wieviele Nullen hat eine Milliarde?",
        antwort: "9",
      },
      {
        frage: "Was ist größer, ein Kilobyte oder ein Megabyte?",
        antwort: "Ein Megabyte! 1000 Kilobyte (KB) = 1 Megabyte (MB)",
      },
      {
        frage: "Wer schrieb 'Romeo und Julia'?",
        antwort: "William Shakespeare",
      },
      {
        frage: "Wie viele Tage hat ein Schaltjahr?",
        antwort: "366. Der 29. Februar kommt hinzu.",
      },
      {
        frage: "Wie viele Ecken hat ein Würfel?",
        antwort: "8",
      },
      {
        frage: "Wieviel Buchstaben hat das deutsche Alphabet?",
        antwort: "26",
      },
      {
        frage: "Für was steht die Abkürzung 'BMW'?",
        antwort: "Bayrische Motoren Werke",
      },
      {
        frage: "Auf welchem Kontinent liegt Ägypten?",
        antwort: "Afrika",
      },
      {
        frage: "Was ist das größte Tier auf der Welt?",
        antwort:
          "Der Blauwal. Blauwale können eine Länge von bis zu 30 Metern und ein Gewicht von bis zu 200 Tonnen erreichen.",
      },
      {
        frage: "Was ist der Hauptbestandteil von Glas?",
        antwort: "Sand",
      },
      {
        frage: "In welcher Einheit wird elektrischer Strom gemessen?",
        antwort: "Ampere",
      },
      {
        frage: "Wie heißt das höchste Gebäude der Welt?",
        antwort: "Burj Khalifa in Dubai (828m, 163 Stockwerte)",
      },
      {
        frage: "Wie nennt man ein Junges von einem Pferd?",
        antwort: "Fohlen",
      },
      {
        frage: "In welcher Stadt befindet sich die Freiheitsstatue?",
        antwort: "New York",
      },
      {
        frage: "Wie heißt der größte Planet in unserem Sonnensystem?",
        antwort: "Jupiter",
      },
      {
        frage: "Wie heißt der reichste Deutsche (Stand: 2023)?",
        antwort: "Dieter Schwarz (47,8 Milliarden US-Dollar)",
      },
    ],
  },
  {
    id: 2,
    name: "Rate mal, Schätzchen",
    shortName: "rateMalSchaetzchen",
    description: "Ratlose Gesichter, wilde Zahlen – das chaotische Schätzfest!",
    active: true,
    currentlyActive: false,
    tasks: [
      {
        frage:
          "Wie viel Fleisch hat jeder Deutsche durchschnittlich im Jahr 2022 verbraucht?",
        antwort: "57 Kilogramm",
      },
      {
        frage:
          "Wie viele Liter Bier wurden 2021 in Deutschland pro Kopf konsumiert?",
        antwort: "95 Liter",
      },
      {
        frage:
          "Wie viele Stunden verbrachte der durchschnittliche Deutsche 2022 täglich im Internet?",
        antwort: "3,5 Stunden",
      },
      {
        frage:
          "Wie viele Kilometer beträgt die Gesamtlänge aller deutschen Autobahnen?",
        antwort: "13.200 Kilometer",
      },
      {
        frage: "Wie viele Einwohner hatte Deutschland am 30. September 2023?",
        antwort: "84 607 000",
      },
      {
        frage:
          "Wie viele Stunden Fernsehen schaute der Durchschnittsdeutsche pro Woche im Jahr 2022?",
        antwort: "11 Stunden",
      },
      {
        frage: "Wie viele Bücher liest der Durchschnittsdeutsche pro Jahr?",
        antwort: "12 Bücher",
      },
      {
        frage:
          "Wie viel Schokolade konsumierte der durchschnittliche Deutsche im Jahr 2021?",
        antwort: "9,21 kg",
      },
      {
        frage: "Wie hoch ist der höchste Berg Deutschlands, die Zugspitze?",
        antwort: "2.962 Meter",
      },
      {
        frage: "Wie viele Stufen hat der Kölner Dom?",
        antwort: "533 Stufen",
      },
      {
        frage:
          "Wie viele Kilometer beträgt die Gesamtlänge der Berliner Mauer?",
        antwort: "155 Kilometer",
      },
      {
        frage: "Wie viele Inseln hat Deutschland insgesamt?",
        antwort: "77 Inseln",
      },
      {
        frage:
          "Wie viele Kilometer beträgt die Distanz zwischen Berlin und Paris?",
        antwort: "1.055 Kilometer",
      },
      {
        frage: "Aus welchem Jahr stammt der älteste Baum Deutschlands?",
        antwort:
          "Im Jahr 760 wurde die Sommer-Linde in Schenklengsfeld (Hessen) geplanzt.",
      },
      {
        frage:
          "Wie viel Haushaltsabfall wird jährlich pro Kopf in Deutschland produziert?",
        antwort:
          "2022 lag das Aufkommen von Haushaltsabfällen in Deutschland bei 438 kg je Einwohner.",
      },
      {
        frage: "Wie viele Brücken hat Venedig?",
        antwort: "400.",
      },
      {
        frage: "Wie viele SMS werden weltweit pro Tag verschickt?",
        antwort: "18 Milliarden",
      },
      {
        frage: "Wie viele Atemzüge nimmt ein Mensch im Durchschnitt am Tag?",
        antwort: "20.000 Atemzüge",
      },
      {
        frage: "Wie groß ist die Fläche von Brasilien (in Quadratkilometern)?",
        antwort: "8.515.770 km²",
      },
      {
        frage: "Wie groß ist die Fläche von Indien (in Quadratkilometern)?",
        antwort: "3.287.263 km²",
      },
      {
        frage: "Wie hoch ist das Vermögen von Elon Musk im Jahr 2023?",
        antwort: "254,9 Milliarden US-Dollar.",
      },
      {
        frage: "Wie viele Einwohner hatte Berlin am 31. Dezember 2022?",
        antwort: "3.755.251 Einwohner.",
      },
      {
        frage: "Wie viele Einwohner hatte Stuttgart am 31. Dezember 2022?",
        antwort: "632.865 Einwohner.",
      },
      {
        frage: "Wie viele Einwohner hatte Hamburg am 31. Dezember 2022?",
        antwort: "1.892.122 Einwohner.",
      },
      {
        frage: "Wie viele Einwohner hatte China im Jahr 2022?",
        antwort: "1.425.890.000",
      },
      {
        frage: "Wie viele Einwohner hatte Deutschland am 31. Dezember 2022?",
        antwort: "84.358.845 Einwohner",
      },
      {
        frage: "Wie viele E-Mail werden täglich versendet?",
        antwort: "306 Milliarden",
      },
      {
        frage: "Wie viele Liter Wasser kann ein Kamel in 15 Minuten trinken?",
        antwort: "200 Liter",
      },
    ],
  },
  {
    id: 3,
    name: "Dreimal daneben",
    shortName: "dreimalDaneben",
    description:
      "Einen Punkt erhält man nur, wenn kein anderer Spieler die selbe Antwort hat.",
    active: true,
    currentlyActive: false,
    tasks: [
      {
        frage: "In welchem Jahr wurde Deutschland Fußball Weltmeister?",
        antwort: "1954, 1974, 1990, 2014",
      },
      {
        frage: "Nenne einen deutschen Automobilhersteller?",
        antwort: "Audi, BMW, Mercedes, Opel, Volkswagen",
      },
      {
        frage:
          "Welche vier deutschen Städte haben die meisten Einwohner? Nenne eine.",
        antwort: "Berlin, Hamburg, München, Köln",
      },
      {
        frage: "Nenne ein Nachbarland von Deutschland.",
        antwort:
          "Dänemark, Polen, Tschechische Republik, Österreich, Schweiz, Frankreich, Luxemburg, Belgien, Niederlande",
      },
      {
        frage: "Welche Farben hat das Google Logo?",
        antwort: "Blua, Grün, Gelb, Rot",
      },
      {
        frage:
          "Welche vier deutschen Städte sind Landeshauptstädte und haben mehr als 500.000 Einwohner?",
        antwort: "München, Hamburg, Dresden, Stuttgart",
      },
      {
        frage: "Nenne eine/n deutsche/n Bundeskanzler/in.",
        antwort:
          "Olaf Scholz, Angela Merkel, Gerhard Schröder, Helmut Kohl, Helmut Schmidt, Willy Brandt, Kurt Georg Kiesinger, Ludwig Erhard, Konrad Adenauer",
      },
      {
        frage: "Welche Automarke gehört zum Volkswagen-Konzern?",
        antwort: "VW, Audi, Skoda, Seat, Cupra, Lamborghini, Bentley, Porsche",
      },
      {
        frage:
          "Nenne einen bekannten deutschen Komponisten für klassische Musik.",
        antwort:
          "Johan Sebastian Bach, Ludwig van Beethoven, Richard Wagner, Georg Friedrich Händel",
      },
      {
        frage: "Nenne eines der vier größten Bundesländer Deutschlands.",
        antwort: "Bayern, Baden-Württemberg, Niedersachen, Nordrhein-Westfalen",
      },
      {
        frage: "Nenne einen Planeten in unserem Sonnensystem.",
        antwort: "Merkur, Venus, Erde, Mars, Jupiter, Saturn, Uranus, Neptun",
      },
      {
        frage:
          "Nenne eine Farbe, die in der Nationalflagge von Italien vorkommt.",
        antwort: "Grün, weiß, rot",
      },
      {
        frage: "Nenne eine der fünf Grundgeschmacksrichtungen.",
        antwort: "dSüß, Sauer, Salzig, Bitter, Umami",
      },
      {
        frage: "Nenne eines der fünf Bücher von Moses im Alten Testament.",
        antwort: "Genesis, Exodus, Levitikus, Numeri, Deuteronomium",
      },
      {
        frage: "Nenne eines der fünf menschlichen Sinnesorgane.",
        antwort: "Augen, Ohren, Nase, Zunge, Haut",
      },
      {
        frage: "Nenne eines der sieben Weltwunder.",
        antwort:
          "Große Pyramide von Gizeh, Hängende Gärten von Babylon, Statue des Zeus in Olympia, Tempel der Artemis in Ephesos, Mausoleum in Halikarnassos, Koloss von Rhodos, Leuchtturm von Alexandria.",
      },
    ],
  },
  {
    id: 4,
    name: "Songtext-Blamage",
    shortName: "songtextBlamage",
    description: "Errate den Song anhand des Songtextes",
    active: false,
    currentlyActive: false,
    tasks: [
      {
        frage: "ddd?",
        antwort: "ddd",
      },
      {
        frage: "ddd?",
        antwort: "ddd",
      },
      {
        frage: "ddd?",
        antwort: "ddd",
      },
      {
        frage: "ddd?",
        antwort: "ddd",
      },
      {
        frage: "ddd?",
        antwort: "ddd",
      },
      {
        frage: "ddd?",
        antwort: "ddd",
      },
    ],
  },
  {
    id: 5,
    name: "Nicht genug für Gold",
    shortName: "nichtGenugFuerGold",
    description:
      "Wir schätzen auch den zweiten Platz! Aber wer war das nochmal?",
    active: true,
    currentlyActive: false,
    tasks: [
      {
        frage:
          "Welches Land wurde bei der Fußball-Weltmeisterschaft 2022 Zweiter?",
        antwort: "Frankreich",
      },
      {
        frage:
          "Welches Land wurde bei der Fußball-Weltmeisterschaft 2018 Zweiter?",
        antwort: "Kroatien",
      },
      {
        frage: "Was ist das zweitgrößte Land der Welt?",
        antwort: "Kanada (nach Russland).",
      },
      {
        frage: "Wer ist der zweitreichste Mensch auf der Welt (2023)?",
        antwort: "Bernard Arnault",
      },
      {
        frage:
          "Welches Land hat die zweithöchste Einwohnerzahl auf der Welt (2022)?",
        antwort:
          "Indien (1.417.170.000). Davor ist China mit 1.425.890.000 Einwohnern.",
      },
      {
        frage: "Was ist das zweitkleinste Land auf der Welt?",
        antwort:
          "Monaco (etwa 2 Quadratkilometer). Davor ist der Vatikanstaat.",
      },
      {
        frage: "Was ist das zweitkleinste Planet in unserem Sonnensystem?",
        antwort: "Merkur",
      },
      {
        frage: "Was ist das zweitgrößte Planet in unserem Sonnensystem?",
        antwort: "Saturn",
      },
      {
        frage: "Was ist das zweitschnellste Landtier der Welt?",
        antwort: "Springbock (bis zu 90 km/h). Davor der Gepard mit 120 km/h.",
      },
      {
        frage: "Was ist der zweitlängste Fluss Deutschlands?",
        antwort: "Elbe (727 km). Davor ist der Rhein mit 865 km.",
      },
      {
        frage: "Welche Sprache ist die zweithäufigste Muttersprache der Welt?",
        antwort:
          "Spanisch (390 Millionen Personen). Davor ist Mandarin-Chinesisch mit 935 Millionen Personen.",
      },
      {
        frage: "Welcher Berg ist der zweithöchste Berg der Welt?",
        antwort: "K2",
      },
    ],
  },
  {
    id: 6,
    name: "beMERKenswert",
    shortName: "bemerkenswert",
    description:
      "Merk dir die Antworten - Ein Text voller Fragen, aber erst am Ende darfst du notieren.",
    active: false,
    currentlyActive: false,
    tasks: [
      {
        frage: "ddd?",
        antwort: "ddd",
      },
      {
        frage: "ddd?",
        antwort: "ddd",
      },
      {
        frage: "ddd?",
        antwort: "ddd",
      },
      {
        frage: "ddd?",
        antwort: "ddd",
      },
      {
        frage: "ddd?",
        antwort: "ddd",
      },
      {
        frage: "ddd?",
        antwort: "ddd",
      },
    ],
  },
  {
    id: 7,
    name: "Unnützes Wissen",
    shortName: "ddd",
    description: "Unnötiges Wissen.",
    active: false,
    currentlyActive: false,
    tasks: [
      {
        frage: "ddd?",
        antwort: "ddd",
      },
      {
        frage: "ddd?",
        antwort: "ddd",
      },
      {
        frage: "ddd?",
        antwort: "ddd",
      },
      {
        frage: "ddd?",
        antwort: "ddd",
      },
      {
        frage: "ddd?",
        antwort: "ddd",
      },
    ],
  },
  {
    id: 8,
    name: "Witzbold-Karussell",
    shortName: "witzboldKarussel",
    description: "Scherzfragen.",
    active: false,
    currentlyActive: false,

    tasks: [
      {
        frage: "Was wird nass, während es trocknet?",
        antwort: "Ein Handtuch",
      },
      {
        frage: "Was wird größer, je mehr man wegnimmt?",
        antwort: "Ein Loch",
      },
      {
        frage: "Was kann reisen um die Welt, während es in einer Ecke bleibt?",
        antwort: "Eine Briefmarke",
      },
      {
        frage: "Was hat einen Hals, aber keinen Kopf?",
        antwort: "Eine Flasche",
      },
    ],
  },
  {
    id: 9,
    name: "Höpfingen",
    shortName: "hoepfingen",
    description: "Fragen zu Höpfingen.",
    active: true,
    currentlyActive: false,

    tasks: [
      {
        frage: "Wie viele Einwohner hat Höpfingen? (31.12.2021)",
        antwort: "3014",
      },
      {
        frage: "Wie viele eingetragene Vereine (e.V.) gibt es in Höpfingen?",
        antwort: "20",
      },
      {
        frage: "Was ist die Fläche von Höpfingen? ",
        antwort: "30,49 km2 ",
      },
      {
        frage: "Wann wurde Höpfingen gegründet?",
        antwort: "996",
      },
      {
        frage: "Wie hoch liegt Höpfingen (über dem Meeresspiegel)?",
        antwort: "377 m über den Meeresspiegel",
      },
      {
        frage: "Wie heißt der aktuelle Bürgermeister von Höpfingen",
        antwort: "Christian Hauk",
      },
    ],
  },
  {
    id: 10,
    name: "Geographie",
    shortName: "geographie",
    description: "Fragen zu geographischen Themen.",
    active: true,
    currentlyActive: false,
    tasks: [
      {
        frage: "Welcher ist der längste Fluss der Welt?",
        antwort: "Der Nil",
      },
      {
        frage: "In welchem Land liegt der Mount Everest?",
        antwort: "Nepal",
      },
      {
        frage: "Welches ist das größte Land der Welt nach Fläche?",
        antwort: "Russland",
      },
      {
        frage: "Was ist die Hauptstadt der Slowakei?",
        antwort: "Bratislava",
      },
      {
        frage: "Welcher Kontinent ist der kleinste?",
        antwort: "Australien",
      },
      {
        frage: "Welcher Fluss fließt durch Hamburg?",
        antwort: "Elbe (und Alster)",
      },
      {
        frage: "Welcher Ozean ist der größte?",
        antwort: "Der Pazifische Ozean",
      },
      {
        frage: "Welches Land hat die meisten Nachbarländer?",
        antwort: "China",
      },
      {
        frage: "Welche ist die größte Insel der Welt?",
        antwort: "Grönland",
      },
      {
        frage: "Was ist die Landeshauptstadt von Hessen?",
        antwort: "Wiesbaden",
      },
      {
        frage: "Was ist das größte Bundesland Deutschlands?",
        antwort: "Bayern",
      },
      {
        frage: "Welcher ist der höchste Berg in Europa?",
        antwort: "Der Mont Blanc",
      },
      {
        frage: "Welcher Fluss ist der längste in Europa?",
        antwort: "Die Wolga",
      },
      {
        frage: "In welcher Stadt befindet sich der berühmte Times Square?",
        antwort: "New York City",
      },
      {
        frage: "Welches Land hat die längste Küstenlinie der Welt?",
        antwort: "Kanada",
      },
      {
        frage: "Welche Stadt ist die Hauptstadt von Australien?",
        antwort: "Canberra",
      },
      {
        frage: "In welchem Land liegt der Amazonas-Regenwald hauptsächlich?",
        antwort: "Brasilien",
      },
      {
        frage: "Welches ist der tiefste See der Welt?",
        antwort: "Der Baikalsee",
      },
      {
        frage: "Wie heißt der größte See in Deutschland?",
        antwort: "Der Bodensee",
      },
      {
        frage: "Welche Stadt ist die Hauptstadt von Schweden?",
        antwort: "Stockholm",
      },
      {
        frage: "Welches Land ist bekannt als das 'Land der aufgehenden Sonne'?",
        antwort: "Japan"
      },
      {
        frage: "Welcher Fluss ist der längste in Afrika?",
        antwort: "Der Nil"
      },
      {
        frage: "Welches Gebirge bildet die Grenze zwischen Europa und Asien?",
        antwort: "Der Ural"
      },
      {
        frage: "In welchem Land befindet sich der Taj Mahal?",
        antwort: "Indien"
      },
      {
        frage: "Welcher Fluss fließt durch London?",
        antwort: "Themse"
      },
      {
        frage: "Auf welchem Kontinent liegt Ägypten?",
        antwort: "Afrika"
      }, 
    ],
  },
  {
    id: 11,
    name: "Deutsche Geschichte",
    shortName: "deutscheGeschichte",
    description: "Fragen zur deutschen Geschichte.",
    active: true,
    currentlyActive: false,
    tasks: [
      {
        frage: "In welchem Jahr fiel die Berliner Mauer?",
        antwort: "1989",
      },
      {
        frage:
          "Wer war der erste Bundeskanzler der Bundesrepublik Deutschland?",
        antwort: "Konrad Adenauer",
      },
      {
        frage: "In welchem Jahr begann der Zweite Weltkrieg?",
        antwort: "1939",
      },
      {
        frage:
          "Wie hieß der deutsche Staat, der von 1949 bis 1990 existierte und im Osten lag?",
        antwort: "Deutsche Demokratische Republik (DDR)",
      },
      {
        frage: "Wann wurde die Bundesrepublik Deutschland offiziell gegründet?",
        antwort: "1949",
      },
      {
        frage:
          "Welcher Vertrag beendete offiziell den Ersten Weltkrieg aus deutscher Sicht?",
        antwort: "Vertrag von Versailles (1919)",
      },
      {
        frage: "Wer war der erste Präsident der Weimarer Republikdd?",
        antwort: "Friedrich Ebert",
      },
      {
        frage: "In welchem Jahr fand die Wiedervereinigung Deutschlands statt?",
        antwort: "1990",
      },
      {
        frage: "Wer war der letzte Kaiser des Deutschen Reiches?",
        antwort: "Kaiser Wilhelm II",
      },
      {
        frage:
          "In welchem Jahr wurde das Frauenwahlrecht in Deutschland eingeführt?",
        antwort: "1918",
      },
      {
        frage:
          "Was sind die bekannten Worte aus der historischen Rede von John F. Kennedy von 1963 in Berlin?",
        antwort: "Ich bin ein Berliner",
      },
      {
        frage:
          "Wer baute das erste Automobil, das als Beginn des modernen Automobilbaus gilt?",
        antwort: "Karl Benz",
      },
      {
        frage:
          "Welche Stadt wurde im Zweiten Weltkrieg durch alliierte Bombenangriffe stark zerstört und symbolisiert das Kriegsende in Deutschland?",
        antwort: "Dresden",
      },
      {
        frage: "Wer war der erste Kaiser des Deutschen Kaiserreiches?",
        antwort: "Kaiser Wilhelm I.",
      },
    ],
  },
  {
    id: 12,
    name: "Tückische Tatsachen",
    shortName: "tueckischeTatsachen",
    description: "Fragen zu Fakten, die oft missverstanden werden.",
    active: true,
    currentlyActive: false,
    tasks: [
      {
        frage: "Wie lange dauerte der Hundertjährige Krieg?",
        antwort: "116 Jahre",
      },
      {
        frage: "Welches Metall ist Hauptbestandteil einer Bleistiftmine?",
        antwort: "Graphit",
      },
      {
        frage: "In welchem Monat feiern die Russen die Oktoberrevolution?",
        antwort: "November",
      },
      {
        frage: "Von welchem Tier stammt das Kamelhaar für Künstlerpinsel?",
        antwort: "Ziegen oder Pferde, nicht Kamele",
      },
      {
        frage: "In welchem Land wurden Croissants erfunden?",
        antwort: "Österreich",
      },
      {
        frage: "Welche Farbe hat das Blut eines Hummers?",
        antwort: "Blau",
      },
      {
        frage: "In welchem Land wurden die 'Glückskekse' erfunden?",
        antwort: "USA",
      },
      {
        frage: "Welches Tier ist tatsächlich das Nationaltier von Schottland?",
        antwort: "Einhorn",
      },
      {
        frage:
          "Welches Vitamin ist in Karotten in hoher Konzentration enthalten?",
        antwort: "Vitamin A",
      },
      {
        frage:
          "Welches Tier ist für die meisten menschlichen Todesfälle weltweit verantwortlich?",
        antwort: "Mücke",
      },
      {
        frage: "Welcher Kontinent ist tatsächlich der trockenste der Erde?",
        antwort:
          "Die Antarktis. Auf dem Kontinent gibt es kaum Niederschlag, aber weil es so kalt ist, schmilzt die geringe Niederschlagsmenge, die fällt, nicht",
      },
      {
        frage: "Was war ursprünglich die Hauptzutat von Ketchup?",
        antwort: "Fisch (Gelbfisch)",
      },
      {
        frage: "Was war die ursprüngliche Farbe von Coca-Cola?",
        antwort: "Grün",
      },
    ],
  },
  {
    id: 13,
    name: "Deutschrap",
    shortName: "deutschrap",
    description: "Fragen rund um deutschen Rap & Hip-Hop.",
    active: true,
    currentlyActive: false,
    tasks: [
      {
        frage: 'Für was steht die Abkürzung des Namens "Sido"?',
        antwort: "Scheiße in dein Ohr oder super-intelligentes Drogenopfer",
      },
      {
        frage: "Welcher Rapper wird häufig auch Siggi genannt?",
        antwort: "Sido",
      },
      {
        frage: "In welchem Monat feiern die Russen die Oktoberrevolution?",
        antwort: "November",
      },
      {
        frage: "Welcher deutsche Rapper ist bekannt für seine Panda-Maske?",
        antwort: "Cro",
      },
      {
        frage:
          "Welches Duo bildeten die Rapper Sido und Bushido unter einem Projektnamen?",
        antwort: "23",
      },
      {
        frage:
          "Mit welchem Song erreichte der Rapper Capital Bra seinen ersten Nummer-1-Hit in Deutschland?",
        antwort: "5 Songs in einer Nacht",
      },
      {
        frage:
          "Welcher Rapper veröffentlichte das Album 'Palmen aus Plastik' zusammen mit RAF Camora?",
        antwort: "Bonez MC",
      },
      {
        frage:
          "Welche deutschen Rapper veröffentlichten das Album 'JBG 3' gemeinsam?",
        antwort: "Kollegah und Farid Bang",
      },
      {
        frage: "Wer ist der Gründer des Hip-Hop-Labels 'Ersguterjunge'?",
        antwort: "Bushido",
      },
      {
        frage: "Wie hieß das Hip-Hop-Duo bestehend aus Nura und Juju?",
        antwort: "SXTN",
      },
      {
        frage: "In welcher Stadt fand das erste 'Splash!' Festival statt??",
        antwort: "Chemnitz",
      },
      {
        frage:
          "Welches Mitglied der 'Fantastischen Vier' ist auch als Solokünstler erfolgreich?",
        antwort: "Smudo",
      },
      {
        frage: "Für welchen Song erhielt der Rapper Samy Deluxe einen Echo??",
        antwort: "Weck mich auf",
      },
      {
        frage: "In welcher deutschen Stadt wurde Haftbefehl geboren??",
        antwort: "Offenbach am Main",
      },
      {
        frage:
          "In welcher Comedy-Fernsehserie hatte Eko Fresh eine wiederkehrende Rolle?",
        antwort: "Blockbustaz",
      },
      {
        frage: "In welchem Jahr wurde Shindy geboren?",
        antwort: "1988",
      },
      {
        frage: "Was ist der bürgerliche Name von Shindy?",
        antwort: "Michael Schindler",
      },
      {
        frage: "Wie heißt das von PA Sports gegründete Musiklabel?",
        antwort: "Life is Pain",
      },
      {
        frage: "In welcher Fernsehshow war Kay One als Jurymitglied tätig??",
        antwort: "Deutschland sucht den Superstar (DSDS)",
      },
      {
        frage: "Was ist der bürgerliche Name von Ufo361?",
        antwort: "Ufuk Bayraktar",
      },
      {
        frage: "Zu welcher Graffiti-Crew gehörte Ufo361 früher?",
        antwort: "THC Gang",
      },
      {
        frage: "Was ist der bürgerliche Name von GZUZ?",
        antwort: "Kristoffer Jonas Klauß",
      },
      {
        frage: "Was bedeutet der Künstlername GZUZ?",
        antwort: "Ghetto-Zeug unzensiert",
      },
      {
        frage: "Nenne ein Mitglied der 187-Straßenbande",
        antwort: "Ghetto-Zeug unzensiert",
      },
      {
        frage: "Nenne einen Künstler, der einmal bei Aggro Music war.",
        antwort: "Ghetto-Zeug unzensiert",
      },
      {
        frage: "Nenne einen Rapper, der ein eigenes Label führt.",
        antwort: "Ghetto-Zeug unzensiert",
      },
      {
        frage: "Nenne einen Rapper, der zu Selfmade Records gehört.",
        antwort: "Ghetto-Zeug unzensiert",
      },
      {
        frage: "Nenne einen Rapper, der zu Banger Musik gehört.",
        antwort: "Ghetto-Zeug unzensiert",
      },
      {
        frage: "Von wem stammt der Rap-Song 'Peter Pan'?",
        antwort: "Ghetto-Zeug unzensiert",
      },
      {
        frage:
          "Nenne einen deutschen Rapper, der in der Serie '4 Blocks' mitspielt.",
        antwort: "Veysel, Massiv, Gringo, Eunique, Gzuz, Damion Davis",
      },
      {
        frage:
          "Welcher deutsche Rapper ist auch unter dem Namen 'Baba aller Babas' bekannt?",
        antwort: "SSIO",
      },
    ],
  },
  {
    id: 14,
    name: "Disney-Zauber",
    shortName: "disneyZauber",
    description: "Spannende Fragen rund um die magische Welt von Disney.",
    active: true,
    currentlyActive: false,
    tasks: [
      {
        frage: "In welchem Jahr wurde das erste Disney-Studio gegründet?",
        antwort: "1923",
      },
      {
        frage:
          "Welcher Film war der erste abendfüllende Zeichentrickfilm von Disney?",
        antwort: "Schneewittchen und die sieben Zwerge",
      },
      {
        frage: "Wie lautet der Name des ersten Original-Disney-Charakters?",
        antwort: "Oswald, der glückliche Hase",
      },
      {
        frage:
          "In welchem Disney-Film findet man die Lieder 'Unter dem Meer' und 'Küss sie doch'?",
        antwort: "Arielle, die Meerjungfrau",
      },
      {
        frage:
          "Welche Disney-Figur hat einen Stern auf dem Hollywood Walk of Fame?",
        antwort: "Mickey Maus",
      },
      {
        frage:
          "Welcher Disney-Film basiert auf einem echten historischen Ereignis?",
        antwort: "Pocahontas",
      },
      {
        frage: "In welchem Disney-Film heißt der Hauptbösewicht Scar?",
        antwort: "Der König der Löwen",
      },
      {
        frage:
          "Welches Lied aus 'Die Eiskönigin' wurde zu einem weltweiten Hit?",
        antwort: "Let it go / Lass jetzt los",
      },
      {
        frage: "Auf welchem Film basiert die Geschichte von Maleficent?",
        antwort: "Dornröschen",
      },
      {
        frage: "Was trägt Schneewittchen auf dem Kopf?",
        antwort: "Eine rote Schleife",
      },
      {
        frage: "dddd?",
        antwort: "Eddddde",
      },
      {
        frage:
          "In welchem Disney-Film spielt eine Uhrwerksfigur namens Cogsworth eine wichtige Rolle??",
        antwort: "Die Schöne und das Biest",
      },
      {
        frage:
          "Welche Disney-Prinzessin hat die Fähigkeit, Eis und Schnee zu kontrollieren?",
        antwort: "Elsa (aus 'Die Eiskönigin')",
      },
      {
        frage: "Was für ein Lebewesen ist Mushu?",
        antwort: "Ein sprechender Drache",
      },
      {
        frage: "In welchem Film kommt Mushu vor?",
        antwort: "Mulan",
      },
      {
        frage: "Welcher Disney-Film spielt in der fiktiven Stadt Agrabah??",
        antwort: "Aladdin",
      },
      {
        frage: "In welchem Disney-Film ist der Hauptcharakter ein Auto?",
        antwort: "Cars",
      },
      {
        frage: "Wie heißt der Feind von Peter Pan?",
        antwort: "Captain Hook",
      },
      {
        frage:
          "Welcher Disney-Film zeigt eine Liebesgeschichte zwischen einem Fuchs und einem Hund?",
        antwort: "Cap und Capper",
      },
      {
        frage: "Wie heißen Simbas beste Freunde?",
        antwort: "Timon und Pumba",
      },
      {
        frage: "Wie heißt der König der Löwen zu Beginn?",
        antwort: "Mufasa",
      },
    ],
  },
  {
    id: 15,
    name: "Märchen-Wahnsinn",
    shortName: "maerchenWahnsinn",
    description: "Fragen rund um die klassischen und beliebten Märchen.",
    active: true,
    currentlyActive: false,
    tasks: [
      {
        frage: "Was verliert Aschenputtel, als sie die Treppe hinunterläuft?",
        antwort: "Einen gläsernen Schuh",
      },
      {
        frage: "Wie viele Zwerge leben zusammen mit Schneewittchen?",
        antwort: "Sieben",
      },
      {
        frage: "Welcher Körperteil wächst bei Pinocchio, wenn er lügt?",
        antwort: "Seine Nase",
      },
      {
        frage:
          "Was benötigt der Froschkönig von der Prinzessin, um sich zurückzuverwandeln?",
        antwort: "Einen Kuss",
      },
      {
        frage: "Wie kann Dornröschen aus ihrem ewigen Schlaf erweckt werden?",
        antwort: "Durch den Kuss der wahren Liebe",
      },
      {
        frage: "Woraus ist das Haus der Hexe in 'Hänsel und Gretel' gemacht?",
        antwort: "Lebkuchen und Süßigkeiten",
      },
      {
        frage: "Wie viele Brüder hat die kleine Meerjungfrau?",
        antwort: "Sechs",
      },
      {
        frage: "Was ist das besondere Merkmal von Rumpelstilzchen?",
        antwort: "Es kann Stroh zu Gold spinnen",
      },
    ],
  },
  {
    id: 16,
    name: "Held oder Hirnlos?",
    shortName: "heldHirnlos",
    description:
      "Spannende Fragen zu den faszinierenden Welten von Marvel und DC.",
    active: true,
    currentlyActive: false,
    tasks: [
      {
        frage: "Wie heißt der Erfinder des Iron Man-Anzugs?",
        antwort: "Tony Stark",
      },
      {
        frage: "Welcher Planet ist die Heimat von Superman in den DC Comics?",
        antwort: "Krypton",
      },
      {
        frage:
          "In welchem Film trat Spider-Man zum ersten Mal im Marvel Cinematic Universe auf?",
        antwort: "Captain America: Civil War",
      },
      {
        frage:
          "Wie heißt der Wissenschaftler, der Bruce Banner in den Marvel Comics in Hulk verwandelte?",
        antwort: "Dr. Bruce Banner selbst",
      },
      {
        frage:
          "Welcher Charakter führte in den DC Comics das Team der Justice League als erster an?",
        antwort: "Batman",
      },
      {
        frage: "Wer erschuf den Charakter von Iron Man in den Marvel Comics?",
        antwort: "Stan Lee",
      },
      {
        frage: "Welcher Superheld erlangt seine Stärke durch einen Hammer?",
        antwort: "Thor",
      },
      {
        frage: "Welcher Schauspieler spielte Batman im Film 'The Dark Knight'?",
        antwort: "Christian Bale",
      },
      {
        frage:
          "Welcher DC-Superheld stammt ursprünglich aus dem fiktiven Land Themyscira?",
        antwort: "Wonder Woman",
      },
      {
        frage:
          "Welcher Marvel-Charakter ist bekannt dafür, mit Pfeil und Bogen zu kämpfen?",
        antwort: "Hawkeye",
      },
      {
        frage:
          "Wer ist der König von Wakanda und der Held namens Black Panther?",
        antwort: "T'Challa",
      },
      {
        frage:
          "Wer kann mithilfe von Gedankenkraft Gegenstände bewegen und Realitäten verändern?",
        antwort: "Scarlet Witch",
      },
      {
        frage: "Was ist der echte Name von Spiderman?",
        antwort: "Peter (Benjamin) Parker",
      },
      {
        frage: "Wie erlangt Spiderman seine Kräfte?",
        antwort: "Durch den Biss einer Spinne",
      },
      {
        frage: "Was ist Loki zu Thor?",
        antwort: "Adoptivbruder",
      },
      {
        frage:
          "Wie lautet der bürgerliche Name von Deadpool in den Marvel-Comics und Filmen?",
        antwort: "Wade Wilson",
      },
      {
        frage:
          "Welcher Schauspieler verkörpert Deadpool in den gleichnamigen Filmen?",
        antwort: "Ryan Reynolds",
      },
      {
        frage:
          "Welche besondere Fähigkeit macht Deadpool in den Filmen nahezu unsterblich?",
        antwort: "Schnelle Selbstheilung",
      },
      {
        frage: "Welchen Beruf hatte Wade Wilson, bevor er zu Deadpool wurde?",
        antwort: "Söldner",
      },
      {
        frage:
          "Welcher Charakter versucht wiederholt, Deadpool für das X-Men-Team zu rekrutieren?",
        antwort: "Colossus",
      },
    ],
  },
];
