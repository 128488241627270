<template>
    <div v-if="game.active" class="game-card">
        <router-link :to="gameLink" @click="setCurrentGame">{{ game.name }}</router-link>
    </div>
</template>

<script>
import Store from '@/store.js';

    export default {
        name: "GameCard",
        components: {

        },
        props: {
            // String, Number, Boolean, Array, Object, Function -- auch möglich, um mehrere Elemente hinzuzuweisen: [String, Number]
            // day: Object // Kurzform

            // Langform (mehrere Optionen)
            game: {
                type: Object,
                required: true,
                default: function () {
                    return {
                        id: -1,
                        name: "Fehlender Tag",
                        shortName: "Asd"
                    };
                },
            },
        },
        computed: {
            gameLink() {
                //return this.game.shortName;
                return "/game";
            }
        },
        methods: {
            setCurrentGame() {
                Store.mutations.setCurrentGame(this.game);
            }
        }


    }
</script>

<style lang="scss" scoped>
    @import "@/assets/styles/_variables.scss";

    .game-card{
        a{
            display: block;
            widows: 100%;
            height: 100%;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            align-items: center;
            justify-content: center;
            padding:20px 10px;
            border-radius: 12px;
            background-color: $base-color;
            font-weight: 600;
            font-size: 14px;
            color: $text-color;
            text-decoration: none;
        }
    }
</style>