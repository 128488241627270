import { tasklist } from "./tasklist";
import { reactive, readonly } from "vue";

const state = reactive({
  tasklist,
  currentGame: "",
  currentGameTitle: ""
});

const getters = {
    activeTask: () => state.tasklist.find((item) => item.currentlyActive),
    gameTasks: (gameId) => {
        return state.tasklist.find(task => task.id === gameId);
    }
};

const mutations = {
    setCurrentGame(game){
        state.currentGame = game;
    },
    setActiveGame(gameId){
        state.tasklist.forEach((taskObj) => {
            if(taskObj.id == gameId){
                taskObj.currentlyActive = true;
            }
            else{
                taskObj.currentlyActive = false;
            }
        });
    }
};

export default {
  state: readonly(state),
  getters,
  mutations,
};
