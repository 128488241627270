<template>
  <section>
    <div id="container">
      <div class="card-group">
        <GameCard v-for="game in tasklist" :key="game.id" :game="game" />
      </div>
    </div>
  </section>
</template>

<script>
import GameCard from '../components/GameCard.vue';
import Store from '../store.js';

  export default {
    name: "HomeView",
    components: {
      GameCard
    },
    data(){
      return {
        tasklist: Store.state.tasklist
      }
    }
  }
</script>

<style lang="scss" scoped>
  section{
    padding:30px;
  }

  .card-group{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
    grid-auto-rows:1fr;
  }
</style>